import { bootstrapApplication } from "@angular/platform-browser";
import { AppComponent } from "./app/app.component";
import { appConfig } from "./app/app.config";
import { environment } from "./environments/environment";
import { enableProdMode } from "@angular/core";
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(environment.agGridLicense);
if (environment.production) {
  enableProdMode();
  // if (window) {
  //   window.console.log = function () {};
  // }
}

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
