import { Component, OnInit } from "@angular/core";
import { MenuLeftComponent } from "../menu-left/menu-left.component";
import { TopBarComponent } from "../top-bar/top-bar.component";

@Component({
  selector: "app-common-bar",
  templateUrl: "./common-bar.component.html",
  standalone: true,
  imports: [MenuLeftComponent, TopBarComponent]
})
export class CommonBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
