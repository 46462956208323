import { Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { AuthGuard } from "./auth.guard";
import { LoginComponent } from "./modules/login/login.component";
import { AccessDeniedComponent } from "./structure/components/access-denied/access-denied.component";
export const routes: Routes = [
  {
    path: "access-denied",
    component: AccessDeniedComponent,
    data: { title: "Access Denied" },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login" },
  },
  { path: "", redirectTo: "login", pathMatch: "full" },

  {
    path: "code",
    redirectTo: "login",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./modules/duedate-report/duedate-report-routing").then((m) => m.duedateRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Home", breadcrumb: [{ label: "Home", url: "" }] },
  },
  {
    path: "swat",
    loadChildren: () => import("./modules/swat/swat-routing").then((m) => m.swatRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "SWAT" },
  },
  {
    path: "future-projects",
    loadChildren: () =>
      import("./modules/future-projects/future-projects-routing").then(
        (m) => m.futureProjectRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Future Projects" },
  },
  {
    path: "billing-process-history",
    loadChildren: () =>
      import("./modules/billing-process-history/billing-process-history-routing").then(
        (m) => m.billingProcessHistoryRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Billing Process History" },
  },
  {
    path: "due-date-report",
    loadChildren: () =>
      import("./modules/duedate-report/duedate-report-routing").then((m) => m.duedateRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: {
      title: "Due Date Module",
      breadcrumb: [{ label: "Due Date Module", url: "" }],
    },
  },
  {
    path: "productivity-dashboard",
    loadChildren: () =>
      import("./modules/productivity-dashboard/productivity-dashboard-routing").then(
        (m) => m.productivityDashboardRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Productivity Dashboard" },
  },
  {
    path: "exception-management",
    loadChildren: () =>
      import("./modules/exception-management/exception-management-routing").then(
        (m) => m.exceptionManagementRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Home" },
  },
  {
    path: "dm-summary",
    loadChildren: () =>
      import("./modules/group-summary/group-summary-routing").then((m) => m.groupSummaryRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Group Summary" },
  },
  {
    path: "group-overview",
    loadChildren: () =>
      import("./modules/group-overview/group-overview-routing").then((m) => m.groupOverviewRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Group Overview" },
  },
  {
    path: "production-snapshot",
    loadChildren: () =>
      import("./modules/production-snapshot/production-snapshot-routing").then(
        (m) => m.productionSnapshotRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Production Snapshot" },
  },
  {
    path: "project-overview",
    loadChildren: () =>
      import("./modules/project-overview/project-overview-routing").then(
        (m) => m.projectOverviewRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Project Overview" },
  },
  
  {
    path: "secondary-unresolved",
    loadChildren: () =>
      import("./modules/secondary-unresolved/secondary-unresolved-routing").then(
        (m) => m.secondaryUnresolvedRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Secondary Unresolved" },
  },
  {
    path: "store-overview",
    loadChildren: () =>
      import("./modules/store-overview/store-overview-routing").then((m) => m.storeOverviewRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Store Overview" },
  },
  {
    path: "store-searcher",
    loadChildren: () =>
      import("./modules/store-searcher/store-searcher-routing").then((m) => m.storeSearcherRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Store Searcher" },
  },
  {
    path: "user-management",
    loadChildren: () =>
      import("./modules/user-management/user-management-routing").then(
        (m) => m.userManagementRoutes
      ),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "User Management" },
  },
  {
    path: "solve-update-log",
    loadChildren: () =>
      import("./modules/solve-sync/solve-sync-routing").then((m) => m.solveSyncRoutes),
    canActivate: [AuthGuard, MsalGuard],
    data: { title: "Solve Update Log" },
  },
  {
    path: "**",
    redirectTo: "/login",
    pathMatch: "full",
  },
];
