<nav class="cat__menu-left">
  <div class="cat__menu-left__lock cat__menu-left__action--menu-toggle">
    <div class="cat__menu-left__pin-button">
      <div>
        <!-- -->
      </div>
    </div>
  </div>
  <div class="cat__menu-left__logo">
    <a>
      <img alt="" src="assets/modules/dummy-assets/common/img/Armatus-logo.svg" />
    </a>
  </div>
  <div
    class="pull-right text-success"
    style="font-size: smaller; font-style: italic; font-weight: 700; margin-right: 10px"
  >
    <span style="cursor: pointer" (click)="openReleaseNoteModal()">{{ RELEASE_VERSION }}</span>
  </div>
  <div class="cat__menu-left__inner"  *ngIf="loadMenulistFlag">
    <ul class="cat__menu-left__list cat__menu-left__list--root">
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'DD'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="HOME_URL"> <span class="cat__menu-left__icon icmn-home"></span> Home </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'PD' && displayAdminActionEnablePD"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="PRODUCTION_DASHBOARD_URL">
            <span class="cat__menu-left__icon">PD</span> Productivity Dashboard
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'PS'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="PRODUCTION_SNAPSHOT_URL">
            <span class="cat__menu-left__icon">PS</span> Production Snapshot
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'PF'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ PF_PORTAL_URL }}" target="_blank">
            <span class="cat__menu-left__icon">PF</span> PF Dashboard
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'AS'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="SWAT_URL"> <span class="cat__menu-left__icon">SW</span> SWAT </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'SS'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="STORE_SEARCHER_URL">
            <span class="cat__menu-left__icon">SS</span> Store Searcher
          </a>
        </li>
      </div>

      <li
        [hidden]="!showDataMasterGroup"
        class="cat__menu-left__item cat__menu-left__submenu cat__menu-left--colorful--secondary"
        id="dmGroups"
      >
        <a> <span class="cat__menu-left__icon icmn-stack"></span> Data Master </a>
        <ul class="cat__menu-left__list">
          <div *ngFor="let data of menuList; let i = index">
            <li
              *ngIf="data.menu === 'SO'"
              class="cat__menu-left__item dashboard"
              [routerLinkActive]="['cat__menu-left__item--active']"
            >
              <a [routerLink]="CREATE_DATA_MASTER_URL">
                <span class="cat__menu-left__icon">SO</span> Store Overview
              </a>
            </li>
          </div>
          <div *ngFor="let data of menuList; let i = index">
            <li
              *ngIf="data.menu === 'GO'"
              class="cat__menu-left__item dashboard"
              [routerLinkActive]="['cat__menu-left__item--active']"
            >
              <a [routerLink]="DM_CONTROL_PANEL_URL">
                <span class="cat__menu-left__icon">GO</span> Group Overview
              </a>
            </li>
          </div>
          <div *ngFor="let data of menuList; let i = index">
            <li
              *ngIf="data.menu === 'GS'"
              class="cat__menu-left__item dashboard"
              [routerLinkActive]="['cat__menu-left__item--active']"
            >
              <a [routerLink]="GRP_RESULT_SUMMARY_URL">
                <span class="cat__menu-left__icon">GS</span> Group Summary
              </a>
            </li>
          </div>
        </ul>
      </li>

      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'DD'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="DUE_DATE_REPORT_URL">
            <span class="cat__menu-left__icon">DD</span> Due Date Module
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'PO'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="DASHBOARD_URL">
            <span class="cat__menu-left__icon">PO</span> Project Overview
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'UM' && displayAdminActionEnable"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="USERS_URL">
            <span class="cat__menu-left__icon">UM</span> User Management
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'SU'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="SECONDARY_UNRESOLVED_URL">
            <span class="cat__menu-left__icon">SU</span> Secondary Unresolved
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'FP'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="FUTURE_PROJECTS_URL">
            <span class="cat__menu-left__icon">FP</span> Future projects
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'EM'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="EXCEPTION_MANAGEMENT_URL">
            <span class="cat__menu-left__icon">EM</span>
            Exception Management
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'PR'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ PRM_PORTAL_URL }}" target="_blank">
            <span class="cat__menu-left__icon">PR</span> PRM Portal
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'MA'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ MAPPER_PORTAL_URL }}" target="_blank">
            <span class="cat__menu-left__icon">MA</span> Mapper Portal
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'DS'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ DATA_SUPPORT_URL }}" target="_blank">
            <span class="cat__menu-left__icon">DS</span> Data Support Automation
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'AM'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ SUBMISSION_SUMMARY_URL }}" target="_blank">
            <span class="cat__menu-left__icon">AM</span> Audit Module
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'EN'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a href="{{ ENTITYDB_PORTAL_URL }}" target="_blank">
            <span class="cat__menu-left__icon">SP</span> Sales Portal
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'BH'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="BILLING_PROCESS_HISTORY_URL">
            <span class="cat__menu-left__icon">BH</span> Billing Process History
          </a>
        </li>
      </div>
      <div *ngFor="let data of menuList; let i = index">
        <li
          *ngIf="data.menu === 'SL'"
          class="cat__menu-left__item dashboard"
          [routerLinkActive]="['cat__menu-left__item--active']"
        >
          <a [routerLink]="SOLVE_SYNC_URL">
            <span class="cat__menu-left__icon">SL</span> Solve Update Log
          </a>
        </li>
      </div>
    </ul>
  </div>
  <div *ngIf="!loadMenulistFlag">
    <div style="text-align: right;padding-top: 50px;padding-right: 7px;">
        <em class="fa fa-spinner fa-pulse fa-2x fa-fw"> </em
        ><span class="sr-only">Loading...</span>
    </div>
  </div>
</nav>

<div
  class="modal fade"
  id="releaseNoteDisplay"
  tabindex="-1"
  role="dialog"
  aria-labelledby="jobModalLabel"
  aria-hidden="true"
>
  <div class="custom-modal-dialog modal-dialog" role="document">
    <div class="modal-content" style="width: 85% !important; height: 800px; overflow-y: scroll">
      <div class="modal-header">
        <h5 class="modal-title" id="opCodeOverrideModalLabel">Release Notes</h5>
        <button type="button" class="close" (click)="closeRNModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-md-12">
              <div class="accordion" id="accordionExample">
                <div class="card" *ngFor="let roData of releaseNotes; let i = index">
                  <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                      <button
                        style="font-weight: bold; color: #1a0a9e"
                        class="btn btn-link"
                        type="button"
                        data-toggle="collapse"
                        [attr.data-target]="'#collapseOne' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {{ roData.releaseVersion }}
                      </button>
                    </h2>
                  </div>

                  <div
                    [attr.id]="'collapseOne' + i"
                    [ngClass]="{ show: i == 0 }"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <ul>                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary pointer" (click)="closeRNModal()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
