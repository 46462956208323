<div class="cat__top-bar">
  <!-- left aligned items -->
  <div class="cat__top-bar__left">
    <div id="cover-spin" *ngIf="SubscriptionConstantService.LOADER_STATUS">
      <span class="spinnerText">{{ loaderText }}</span>
    </div>
    <div
      *ngIf="displayNotification"
      data-notify="container"
      class="col-xs-11 col-sm-4 alert alert-info animated fadeInDown"
      role="alert"
      data-notify-position="top-right"
      style="
        display: inline-block;
        margin: 0px auto;
        position: fixed;
        transition: all 0.5s ease-in-out;
        z-index: 1031;
        top: 20px;
        right: 20px;
        animation-iteration-count: 1;
      "
    >
      <button
        type="button"
        aria-hidden="true"
        class="close"
        data-notify="dismiss"
        (click)="displayNotification = false"
      >
        �</button
      ><span data-notify="icon"></span> <span data-notify="title"></span>
      <span data-notify="message">{{ SubscriptionConstantService.SUCCESS_MESSAGE }}</span>
      <span (click)="refresh()">
        <em
          class="plus fa fa-refresh fa-2x pointer"
          style="color: #ffffff; float: right; position: relative; right: 12px"
        ></em>
      </span>
    </div>
    <div class="cat__top-bar__item hidden-lg-down">
      <app-ng-dynamic-breadcrumb style="pointer-events: none"></app-ng-dynamic-breadcrumb>
    </div>
    <div class="cat__top-bar__item">
      <button
        type="button"
        class="btn btn-danger ver-mismatch-label"
        *ngIf="versionMismatch =='true'"
        (click)="commonService.hardRefresh()"
      >
        <strong style="margin-right: 6px">Update Version </strong>
        <em
          class="fa fa-refresh fa-1x"
          data-toggle="tooltip"
          data-animation="false"
          data-placement="top"
          title="Click here to Refresh"
        ></em>
      </button>
    </div>
  </div>
  <!-- right aligned items -->
  <div class="cat__top-bar__right">
    <div class="cat__top-bar__item hidden-sm-down"></div>
    <div class="cat__top-bar__item hidden-xxl-down hidden-sm-down"></div>
    <div class="cat__top-bar__item hidden-sm-down"></div>

    <div class="cat__top-bar__item hidden-sm-down">
      <div class="dropdown">
        <a
          href="javascript: void(0);"
          class="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          class="menu-notification-icon fa fa-bell fa-1x"
        >
          <span class="badge badge-danger" style="margin-left: 5px;" *ngIf="eventLogs.length">{{ eventLogs.length }}</span>
        </a>
        <ul
          *ngIf="eventLogs.length"
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby=""
          role="menu"
        >
          <div
            class="cat__top-bar__activity"
            style="height: 350px !important; overflow: auto !important"
          >
            <div
              class="cat__top-bar__activity__item pointer"
              *ngFor="let data of eventLogs; let i = index"
            >
              
              <div class="cat__top-bar__activity__inner">
                <div class="cat__top-bar__activity__title">
                  <span class="pull-right">{{ formateDateTime(data.happenedAt) }}</span>
                  <a href="javascript: void(0);">{{ data.project_name }}</a>
                </div>              
                <div class="cat__top-bar__activity__descr">
                  {{ data.event_type }}
                </div>
              </div>
            </div>
            <div class="cat__top-bar__activity__item pointer">
              <div class="cat__top-bar__activity__title">
                <a
                  href="javascript: void(0);"
                  (click)="clearNotification()"
                  style="text-align: center; display: block"
                  >Clear All</a
                >
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div class="cat__top-bar__item">
      <div class="dropdown cat__top-bar__avatar-dropdown">
        <a
          href="javascript: void(0);"
          class="dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="cat__top-bar__avatar" href="javascript:void(0);">
            <img alt="" src="assets/images/person.png" />
          </span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="" role="menu">
          <a class="dropdown-item" href="javascript:void(0)"
            ><em class="dropdown-icon icmn-user"></em> {{ userName }}</a
          >
          <div class="dropdown-divider"></div>
          <div class="dropdown-header">Home</div>
          <a class="dropdown-item" href="javascript:void(0)"
            ><em class="dropdown-icon fa fa-gear"></em> Settings</a
          >
          <div class="dropdown-divider" *ngIf="adminActionMenuDisplay()"></div>
          <a
            *ngIf="adminActionMenuDisplay() && toggleButtonStatus == 'true'"
            class="dropdown-item pointer"
            href="javascript:void(0)"
            (click)="toggleAdminAction(false)"
            ><em class="fa fa-toggle-on colorBlue"></em>&nbsp;&nbsp;Disable Admin Actions</a
          >
          <a
            *ngIf="adminActionMenuDisplay() && toggleButtonStatus == 'false'"
            class="dropdown-item pointer"
            href="javascript:void(0)"
            (click)="toggleAdminAction(true)"
            ><em class="fa fa-toggle-off"></em>&nbsp;&nbsp;Enable Admin Actions</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item pointer" href="javascript:void(0)" (click)="logout()"
            ><em class="dropdown-icon icmn-exit"></em> Logout</a
          >
        </ul>
      </div>
    </div>
  </div>
</div>
