export const environment = {
  production: false,
  envName: "development",
  authScope: "api://4006d68b-dc72-40cb-8e5b-d7803e5c369a/access_scope",
  redirectUri: "https://devl-adudssportal.sandbox.dealeruplift.net",
  apiUrl: "https://devl-adudssportal.sandbox.dealeruplift.net/backend/",
  agGridLicense:
    "CompanyName=Armatus Dealer Uplift,LicensedApplication=.dealeruplift.net,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-024692,ExpiryDate=10_March_2023_[v2]_MTY3ODQwNjQwMDAwMA==ebb7f15ce5984d1c5dfc7a103e39bea2",
  SOLVE360: "http://graphql2.devl-adudssportal.sandbox.dealeruplift.net/graphiql",
  MAGEURL: "http://graphql1.tmp-devl-adudssportal.sandbox.dealeruplift.net/graphiql",
  clientId: "4006d68b-dc72-40cb-8e5b-d7803e5c369a",
  domainUrl: "https://devl-adudssportal.sandbox.dealeruplift.net",
  webSocketLink: "wss://devl-api.sandbox.dealeruplift.net/subscriptions",
  enableSync: true,
  biDirectionalNotificationFlag: false,
  defaultTestDataTag: true,
  awsAccessKey: "",
  awsSecretKey: "",
  awsRegion: "us-east-1",
  awsBucket: "devl-updates-from-portal",
  awsFolder: "uploaded_as_reports",
  PRMPORTAL: "https://devl-prmportal.sandbox.dealeruplift.net",
  DU_JOBS_URL: "https://uat-portal.production.dealeruplift.net/duJobsGraphqlCustom",
  AUDITOR_URL: "https://devl-auditportal.sandbox.dealeruplift.net/#/auditor-reports",
  AUDITOR_LIST_URL: "https://devl-auditportal.sandbox.dealeruplift.net/#/auditor-list",
  SUBMISSION_SUMMARY_URL: "https://devl-auditportal.sandbox.dealeruplift.net/#/submission-summary",
  AUDITOR_MAIN_URL: "https://devl-auditportal.sandbox.dealeruplift.net",
  MAPPER_PORTAL_URL: "https://mapper.sandbox.dealeruplift.net",
  DATA_SUPPORT_URL: "https://devl-datasupport.sandbox.dealeruplift.net",
  ENTITYDB_PORTAL_URL: "https://entitydb.sandbox.dealeruplift.net",
  salesAppStageUpdateURI:
    "https://entitydb.sandbox.dealeruplift.net/graphql/solve360/v1/company/stage-update",
  auditNotesUIFlag: false,
  projectFacilitationURL: "https://project-facilitation.sandbox.dealeruplift.net",
  PFPORTAL: "https://project-facilitation.sandbox.dealeruplift.net",
  domainUrlLocal: "https://devl-sharepoint.sandbox.dealeruplift.net",
  MAILGUN_DOMAIN: "sandbox.dealeruplift.net",
  MAILGUN_API_KEY: "c1c0270904980d220df01db5c55f8bff",
  ENTITY_SOLVE_USER: "271903646",
};
