import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from "@angular/router";
import {
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { InteractionStatus, EventMessage, EventType } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { CommonBarComponent } from "./structure/components/common-bar/common-bar.component";
import { FooterComponent } from "./structure/components/footer/footer.component";
import { MenuLeftComponent } from "./structure/components/menu-left/menu-left.component";
import { TopBarComponent } from "./structure/components/top-bar/top-bar.component";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    CommonBarComponent,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  isAuthenticated = false;
  requestObj = {
    scopes: ["user.read", "openid", "profile"],
  };
  private readonly _destroying$ = new Subject<void>();
  private GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me";
  constructor(
    private authService: MsalService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private titleService: Title
  ) {}
  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.authService.handleRedirectObservable().subscribe();
    this.setupRouterEvents();
    this.authService.instance
      .initialize()
      .then(() => {
        this.checkAndSetActiveAccount();
        this.subscribeToAuthEvents();
      })
      .catch((err) => {
        console.error("MSAL initialization error: ", err);
      });
  }
  setupRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = "";
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data["title"]) {
            routeTitle = route!.snapshot.data["title"];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`${title}`);
        }
      });
  }
  subscribeToAuthEvents() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }
  enableAccountStorageEvents() {
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg) =>
            msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => this.checkAndSetActiveAccount());
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();
    console.log("Account Detail============>>>>", activeAccount);
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      console.log("Account Detail1============>>>>", this.authService.instance.getActiveAccount());
      localStorage.setItem("userName", String(this.authService.instance.getActiveAccount()?.name));
      const token = this.authService.instance.getActiveAccount()?.idToken;
      console.log("Id Token1=========================>>", token);
      if (token) {
        localStorage.setItem("token", token);
        this.getProfile();
        this.isAuthenticated = true;
      }
    } else if (activeAccount) {
      console.log("Account Detail2============>>>>", this.authService.instance.getActiveAccount());
      localStorage.setItem("userName", String(this.authService.instance.getActiveAccount()?.name));
      const token = this.authService.instance.getActiveAccount()?.idToken;
      console.log("Id Token1=========================>>", token);
      if (token) {
        localStorage.setItem("token", token);
        this.getProfile();
        this.isAuthenticated = true;
      }
    } else {
      this.authService.loginRedirect();
    }
  }

  getProfile() {
    this.http.get(this.GRAPH_ENDPOINT).subscribe((profile) => {
      console.log("profile====>>>>", profile);
      localStorage.setItem("currentUser", JSON.stringify(profile));
      if (!localStorage.getItem("adminAction")) {
        localStorage.setItem("adminAction", "true");
      }
    });
  }

  silentLogin() {
    const account = this.authService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["user.read", "openid", "profile"],
      account: account,
    };
    this.authService.instance
      .acquireTokenSilent(accessTokenRequest)
      .then((result) => {
        console.log("acquireTokenSilent=========================>>", result);
        
        const token = result.idToken;
        console.log("Id Token silent=========================>>", token);
        localStorage.setItem("token", token);
      })
      .catch((error) => {
        console.log("acquireTokenSilent Error======>>", error);
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
