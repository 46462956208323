import { Component, NgZone } from "@angular/core";
import { CommonService } from "../../services/common.service";
import { MsalService } from "@azure/msal-angular";
import { EventEmitterService } from "./../../services/event.emitter.services";
import { SubscriptionConstantService } from "./../../constants/subscription.constant.service";
import { environment } from "src/environments/environment";
import { ConstantService } from "../../constants/constant.service";
import * as moment from "moment-timezone";
import { Router, RouterLink } from "@angular/router";
import { NgIf, NgFor } from "@angular/common";
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';

@Component({
  selector: "app-top-bar",
  templateUrl: "./top-bar.component.html",
  standalone: true,
  imports: [NgIf, NgFor,NgDynamicBreadcrumbModule],
  providers: [SubscriptionConstantService],
})
export class TopBarComponent {
  // isAuthenticated = false;
  public displayNotification = false;
  public isUserAdmin = false;
  public toggleButtonStatus: any = localStorage.getItem("adminAction")
    ? localStorage.getItem("adminAction")
    : false;
  public loaderText = null;
  public screenType = null;
  public displayAdminActionMenu = false;
  public eventLogs: any[] = [];
  public notificationList: any[] = [];

  constructor(
    public EventEmitterService: EventEmitterService,
    public SubscriptionConstantService: SubscriptionConstantService,
    public constantService: ConstantService,
    public commonService: CommonService,
    private authService: MsalService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.EventEmitterService.psProjectUpdateMessage$.subscribe((subscriptionData: any) => {
      console.log("subscriptionData====================================>>>", subscriptionData);
      if (this.eventLogs.length === 20) {
        this.eventLogs.pop();
      }
      const obj: any = Object.assign({}, subscriptionData);
      obj["happenedAt"] = moment(new Date());
      this.eventLogs.unshift(obj);
    });

    /**
     * Event Emitter Subscribe Section - Display Progress Overlay
     */
    console.log("EventEmitterService====================================>>>", EventEmitterService);

    this.EventEmitterService.displayProgressMessage$.subscribe((displayProgress:any) => {
      console.log("displayProgress EmitterService ====================================>>>", displayProgress);

      if (displayProgress) {
        this.loaderText = displayProgress;
        this.SubscriptionConstantService.LOADER_STATUS = true;
      } else {
        this.SubscriptionConstantService.LOADER_STATUS = false;
      }
    });
    /**
     * Event Emitter Subscribe Section - Display Notification
     */
    this.EventEmitterService.displayNotificationMessage$.subscribe((displayNotification) => {
      const notificationData = displayNotification;
      this.displayNotification = environment.biDirectionalNotificationFlag;
      this.SubscriptionConstantService.SUCCESS_MESSAGE = notificationData.message;
      this.screenType = notificationData.type;
    });
  }
  logout() {
    this.authService.logout();
    // localStorage.clear();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("menuItems");
    localStorage.removeItem("userName");
    localStorage.removeItem("adminAction");
    localStorage.removeItem("previousUrl");
  }

  ngOnInit() {
    // this.toggleButtonStatus = localStorage.getItem("adminAction") && localStorage.getItem("adminAction") == 'true' ? 'true': 'false';
    // console.log('toggleButtonStatus====>>>', this.toggleButtonStatus);
  }

  public get versionMismatch() {
    return localStorage.getItem("versionMismatch") ? localStorage.getItem("versionMismatch") : null;
  }

  public get userName() {
    this.toggleButtonStatus =
      localStorage.getItem("adminAction") && localStorage.getItem("adminAction") == "true"
        ? "true"
        : "false";
    // console.log('toggleButtonStatus====>>>', this.toggleButtonStatus);
    return localStorage.getItem("userName") ? localStorage.getItem("userName") : null;
  }

  login() {
    console.log("login...........");
  }

  /**
   * Event emitter - Refresh Page
   */
  refresh() {
    this.displayNotification = false;
    this.ngZone.run(() => {
      // Your logic here
      this.EventEmitterService.refreshScreensSendMessage(this.screenType);
    });
  }

  toggleAdminAction(flag: any) {
    //console.log('toggle flag===>>>', flag);
    this.toggleButtonStatus = flag ? "true" : "false";
    localStorage.setItem("adminAction", this.toggleButtonStatus);
    //console.log('toggleAdminAction===>>>', this.toggleButtonStatus);
    this.EventEmitterService.toggleAdminActionSendMessage(this.toggleButtonStatus);
  }
  adminActionMenuDisplay() {
    const currentMenuItems = JSON.parse(localStorage.getItem("menuItems")!);
    let weightagess = 0;
    if (currentMenuItems) {
      for (let i = 0; i < currentMenuItems.length; i++) {
        if (currentMenuItems[i].menu && currentMenuItems[i].menu === "SO") {
          weightagess = currentMenuItems[i].weightage;
        }
      }
      if (weightagess > this.constantService.ADMIN_WEIGTAGE) {
        this.displayAdminActionMenu = true;
      }
    }
    return this.displayAdminActionMenu;
  }

  clearNotification() {
    this.eventLogs = [];
  }

  formateDateTime(date: any) {
    this.ngZone.run(() => {
      // Your logic here
      return moment(date).fromNow();
    });
  }
  //   /**
  //   * EventLogList list is getting by calling allEventLogList query.
  //   */
  //  eventLogList() {

  //     /**
  //     * Event Emitter Subscribe Section
  //     */

  //   // allEventLogList.refetch().then(listData => {
  //   //   $.each(listData['data']['allEventLogs']['edges'], (key, val) => {
  //   //     const obj = {
  //   //       projectId: val.node['projectId'],
  //   //       projectName: val.node['projectName'] === '' || val.node['projectName'] === null ? null : val.node['projectName'],
  //   //       requestor: val.node['requestor'] === '' || val.node['requestor'] === null ? null : val.node['requestor'],
  //   //       requestedBy: val.node['requestedBy'] === '' || val.node['requestedBy'] === null ? null : val.node['requestedBy'],
  //   //       target: val.node['target'] === '' || val.node['target'] === null ? null : val.node['target'],
  //   //       requestedOf: val.node['requestedOf'] === '' || val.node['requestedOf'] === null ? null : val.node['requestedOf'],
  //   //       requestedAt: val.node['happenedAt'],
  //   //       acknowledgedOn: val.node['acknowledgedOn'],
  //   //       payload: val.node['payload'],
  //   //       eventType: val.node['eventType'],
  //   //       ddejId: val.node['ddejId'],
  //   //     };
  //   //     this.eventLogs.push(obj);
  //   //   });
  //   //   this.notificationList = this.notifications();
  //   // },
  //   //   err => {
  //   //     this.commonService.errorCallback(err, this);
  //   //   }
  //   // );
  //   // return allEventLogList;

  //   this.eventLogs = [{
  //     projectId: 'projectId',
  //     projectName: 'projectName',
  //     requestor: 'requestor',
  //     requestedBy: 'requestedBy',
  //     target: 'target',
  //     requestedOf: 'requestedOf',
  //     requestedAt: 'requestedAt',
  //     acknowledgedOn: 'acknowledgedOn',
  //     payload: 'payload',
  //     eventType: 'eventType',
  //     ddejId: 'ddejId',
  //   }, {
  //     projectId: 'projectId',
  //     projectName: 'projectName',
  //     requestor: 'requestor',
  //     requestedBy: 'requestedBy',
  //     target: 'target',
  //     requestedOf: 'requestedOf',
  //     requestedAt: 'requestedAt',
  //     acknowledgedOn: 'acknowledgedOn',
  //     payload: 'payload',
  //     eventType: 'eventType',
  //     ddejId: 'ddejId',
  //   }];
  //   this.notificationList = this.notifications();

  // }

  /**
   * notifications list is calculated based on the acknowlegedge required and open request of me of current user.
   */
  public notifications() {
    // return this.eventLogs.filter((item, index) => index < this.eventLogs.length)
    //   .filter((item, index) => (item['requestedBy'] === this.userId && item['acknowledgedOn'] === null
    //     && item['eventType'] === 'Response') || (item['requestedOf'] === this.userId && item['acknowledgedOn'] === null
    //       && item['eventType'] === 'Request'));
    return this.eventLogs;
  }

  /**
   * When select a notification on notification list, required data is stored on local storage and redirected to eventlog page.
   */
  public showNotification(eventLog: any) {
    const eventLogObject = {
      ddejId: eventLog.ddejId,
      projectId: eventLog.projectId,
      eventType: eventLog.eventType,
      requestedBy: eventLog.requestedBy,
      requestedOf: eventLog.requestedOf,
    };
    // localStorage.setItem('eventLog', JSON.stringify(eventLogObject));
    // this.router.navigate([this.constantService.EVENT_LOG_URL]);
    // if (this.router.url === this.constantService.EVENT_LOG_URL) {
    //   location.reload();
    // }
  }
}
