import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "safeInput",
})
export class SafeInputPipe implements PipeTransform {
  encoded: any;
  constructor(private sanitized: DomSanitizer) {}
  //pass params.value for UI

  // transform(value: string): SafeHtml {
  //   // Sanitize the input to prevent XSS attacks
  //   return this.sanitized.bypassSecurityTrustHtml(value);
  // }
  transform(value: any) {
    const replacedValue = value 
      ? value.replace(/</g, "&lt;").replace(/>/g, "&gt;")
      : "";
    //return replacedValue;
    return this.sanitized.sanitize(SecurityContext.HTML, replacedValue);
  }

  // Parse method to convert safe HTML back to string (optional)
  parse(value: SafeHtml): string {
      let parsedOutput;
      if (value) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = value as string; // Cast to string for assignment
        parsedOutput = tempDiv.innerText; // Get the text content
      } else {
        parsedOutput = '';
      }
      return parsedOutput;
  }

  transformXss(value: any) {
    const replacedValue = value 
    ? value
     //JSON.stringify(value)
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    : "";
    return replacedValue;
  }
}
