import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: "root", 
})
export class EventEmitterService {

  private psProjectUpdate = new Subject<any>();  // Subject to emit values
  psProjectUpdateMessage$ = this.psProjectUpdate.asObservable();  // Observable to subscribe
  psProjectUpdateSendMessage(psProjectUpdateMessage: any) {
    this.psProjectUpdate.next(psProjectUpdateMessage);  // Emit the message
  }

  private dataSubscription = new Subject<any>();  // Subject to emit values
  dataSubscriptionMessage$ = this.dataSubscription.asObservable();  // Observable to subscribe
  dataSubscriptionSendMessage(dataSubscriptionMessage: any) {
    this.dataSubscription.next(dataSubscriptionMessage);  // Emit the message
  }

  private displayProgress = new Subject<any>();  // Subject to emit values
  displayProgressMessage$ = this.displayProgress.asObservable();  // Observable to subscribe
  displayProgressSendMessage(displayProgressMessage: any) {
    this.displayProgress.next(displayProgressMessage);  // Emit the message
  }

  private displayNotification = new Subject<any>();  // Subject to emit values
  displayNotificationMessage$ = this.displayNotification.asObservable();  // Observable to subscribe
  displayNotificationSendMessage(displayNotificationMessage: any) {
    this.displayNotification.next(displayNotificationMessage);  // Emit the message
  }

  private dataSubscriptionStoreSearcher = new Subject<any>();  // Subject to emit values
  dataSubscriptionStoreSearcherMessage$ = this.dataSubscriptionStoreSearcher.asObservable();  // Observable to subscribe
  dataSubscriptionStoreSearcherSendMessage(dataSubscriptionStoreSearcherMessage: any) {
    this.dataSubscriptionStoreSearcher.next(dataSubscriptionStoreSearcherMessage);  // Emit the message
  }

  private dataSubscriptionSoloStore = new Subject<any>();  // Subject to emit values
  dataSubscriptionSoloStoreMessage$ = this.dataSubscriptionSoloStore.asObservable();  // Observable to subscribe
  dataSubscriptionSoloStoreSendMessage(dataSubscriptionSoloStoreMessage: any) {
    this.dataSubscriptionSoloStore.next(dataSubscriptionSoloStoreMessage);  // Emit the message
  }

  private refreshScreens = new Subject<any>();  // Subject to emit values
  refreshScreensMessage$ = this.refreshScreens.asObservable();  // Observable to subscribe
  refreshScreensSendMessage(refreshScreensMessage: any) {
    this.refreshScreens.next(refreshScreensMessage);  // Emit the message
  }

  private dataSubscriptionProjectParts = new Subject<any>();  // Subject to emit values
  dataSubscriptionProjectPartsMessage$ = this.dataSubscriptionProjectParts.asObservable();  // Observable to subscribe
  dataSubscriptionProjectPartsSendMessage(dataSubscriptionProjectPartsMessage: any) {
    this.dataSubscriptionProjectParts.next(dataSubscriptionProjectPartsMessage);  // Emit the message
  }

  private dataSubscriptionProjectLabor = new Subject<any>();  // Subject to emit values
  dataSubscriptionProjectLaborMessage$ = this.dataSubscriptionProjectLabor.asObservable();  // Observable to subscribe
  dataSubscriptionProjectLaborSendMessage(dataSubscriptionProjectLaborMessage: any) {
    this.dataSubscriptionProjectLabor.next(dataSubscriptionProjectLaborMessage);  // Emit the message
  }

  private toggleAdminAction = new Subject<any>();  // Subject to emit values
  toggleAdminActionMessage$ = this.toggleAdminAction.asObservable();  // Observable to subscribe
  toggleAdminActionSendMessage(toggleAdminActionMessage: any) {
    this.toggleAdminAction.next(toggleAdminActionMessage);  // Emit the message
  }
  // psProjectUpdate: EventEmitter<any> = new EventEmitter<any>();
  // dataSubscription: EventEmitter<any> = new EventEmitter<any>();
  // displayProgress: EventEmitter<any> = new EventEmitter<any>();
  // displayNotification: EventEmitter<any> = new EventEmitter<any>();
  // dataSubscriptionStoreSearcher: EventEmitter<any> = new EventEmitter<any>();
  // dataSubscriptionSoloStore: EventEmitter<any> = new EventEmitter<any>();
  // refreshScreens: EventEmitter<any> = new EventEmitter<any>();
  // dataSubscriptionProjectParts: EventEmitter<any> = new EventEmitter<any>();
  // dataSubscriptionProjectLabor: EventEmitter<any> = new EventEmitter<any>();
  // toggleAdminAction: EventEmitter<any> = new EventEmitter<any>();
}
