import { Component, OnDestroy, OnInit, NgZone, ChangeDetectorRef,inject,ChangeDetectionStrategy
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConstantService } from "../../constants/constant.service";
import { CommonService } from "../../services/common.service";
import { environment } from "src/environments/environment";
declare let $: any;
declare let jQuery: any;
declare let analytics: any;

@Component({
  selector: "app-menu-left",
  templateUrl: "./menu-left-vertical.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConstantService, CommonService],
})
export class MenuLeftComponent implements OnInit, OnDestroy {
  public NAME_URL: any;
  public HOME_URL: any;
  public releaseNotes: any;
  public DASHBOARD_URL: any;
  public PRODUCTION_DASHBOARD_URL: any;
  public SWAT_URL: any;
  public SIM_URL: any;
  public ADD_RO_URL: any;
  public ADD_RO_ITEM_URL: any;
  public EDIT_RO_ITEM_URL: any;
  public LIST_RO_URL: any;
  public REYNOLDS_RO_EXCEPTION_URL: any;
  public UNDER_CONSTRUCTION_URL: any;
  public JOINT_OPTIMIZATION_URL: any;
  public CLONING_SCENARIOS_URL: any;
  public FACTORY_LABOR_FORMS_URL: any;
  public DUE_DATE_REPORT_URL: any;
  public USER_MANAGEMENT_URL: any;
  public SECONDARY_UNRESOLVED_URL: any;
  public STORE_SEARCHER_URL: any;
  public CREATE_DATA_MASTER_URL: any;
  public DM_CONTROL_PANEL_URL: any;
  public RELEASE_VERSION: any;
  public GRP_RESULT_SUMMARY_URL: any;
  public RANGE_SELECTOR_URL: any;
  public INVOICE_GENERATION_URL: any;
  public MAGE_MAPPER_URL: any;
  public PRM_PORTAL_URL: any;
  public PF_PORTAL_URL: any;
  public MAPPER_PORTAL_URL: any;
  public DATA_SUPPORT_URL: any;
  public ENTITYDB_PORTAL_URL: any;
  public MANAGE_PAY_TYPE_URL: any;
  public MAKE_DISPOSITION_URL: any;
  public INVOICE_REBUILD_URL: any;
  public MANAGE_MAIL_URL: any;
  public PRODUCTION_SNAPSHOT_URL: any;
  public USERS_URL: any;
  public FUTURE_PROJECTS_URL: any;
  public EXCEPTION_MANAGEMENT_URL: any;
  public BILLING_PROCESS_HISTORY_URL: any;
  public SUBMISSION_SUMMARY_URL: any;
  public SOLVE_SYNC_URL: any;

  azureGroup = [];
  showManageGroup = false;
  public showDataMasterGroup = false;

  displayAdminActionEnable = false;
  displayAdminActionEnablePD = false;
  public totNoOfExceptionProject = "";
  public envName: any;
  public loadMenulistFlag = false;
  constructor(
    private http: HttpClient,
    public constantService: ConstantService,
    public commonService: CommonService,
    private router: Router,
    private ngZone: NgZone,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  checkAccount() {
    console.log("isAuthenticated............");
  }
  ngOnInit() {
    this.envName = environment.envName;
    // this.azureGroup = [];
    this.showManageGroup = false;
    this.showDataMasterGroup = false;
    this.loadMenulistFlag = false;

    console.log("menuleft............");
    this.RELEASE_VERSION = this.constantService.releaseVerion;
    this.HOME_URL = this.constantService.HOME_URL;

    this.DASHBOARD_URL = this.constantService.DASHBOARD_URL;
    this.PRODUCTION_DASHBOARD_URL = this.constantService.PRODUCTION_DASHBOARD_URL;
    this.SWAT_URL = this.constantService.SWAT_URL;
    this.SIM_URL = this.constantService.SIM_URL;
    this.LIST_RO_URL = this.constantService.LIST_RO;
    this.ADD_RO_URL = this.constantService.ADD_RO;
    this.ADD_RO_ITEM_URL = this.constantService.ADD_RO_ITEM;
    this.EDIT_RO_ITEM_URL = this.constantService.EDIT_RO_ITEM;
    this.REYNOLDS_RO_EXCEPTION_URL = this.constantService.REYNOLDS_RO_EXCEPTION_URL;
    this.JOINT_OPTIMIZATION_URL = this.constantService.JOINT_OPTIMIZATION_URL;
    this.CLONING_SCENARIOS_URL = this.constantService.CLONING_SCENARIOS_URL;
    this.FACTORY_LABOR_FORMS_URL = this.constantService.FACTORY_LABOR_FORMS_URL;
    this.DUE_DATE_REPORT_URL = this.constantService.DUE_DATE_REPORT_URL;
    this.SECONDARY_UNRESOLVED_URL = this.constantService.SECONDARY_UNRESOLVED_URL;
    this.UNDER_CONSTRUCTION_URL = this.constantService.UNDER_CONSTRUCTION_URL;
    this.STORE_SEARCHER_URL = this.constantService.STORE_SEARCHER_URL;
    this.CREATE_DATA_MASTER_URL = this.constantService.CREATE_DATA_MASTER_URL;
    this.DM_CONTROL_PANEL_URL = this.constantService.DM_CONTROL_PANEL_URL;
    this.GRP_RESULT_SUMMARY_URL = this.constantService.GRP_RESULT_SUMMARY_URL;
    this.RANGE_SELECTOR_URL = this.constantService.RANGE_SELECTOR_URL;
    this.INVOICE_GENERATION_URL = this.constantService.INVOICE_GENERATION_URL;
    this.MAGE_MAPPER_URL = this.constantService.MAGE_MAPPER_URL;
    this.PRM_PORTAL_URL = environment.PRMPORTAL;
    this.PF_PORTAL_URL = environment.PFPORTAL;
    this.MAPPER_PORTAL_URL = environment.MAPPER_PORTAL_URL;
    this.DATA_SUPPORT_URL = environment.DATA_SUPPORT_URL;
    this.ENTITYDB_PORTAL_URL = environment.ENTITYDB_PORTAL_URL;
    this.MANAGE_PAY_TYPE_URL = this.constantService.MANAGE_PAY_TYPE_URL;
    this.MAKE_DISPOSITION_URL = this.constantService.MAKE_DISPOSITION_URL;
    this.INVOICE_REBUILD_URL = this.constantService.INVOICE_REBUILD_URL;
    this.MANAGE_MAIL_URL = this.constantService.MANAGE_MAIL_URL;
    this.PRODUCTION_SNAPSHOT_URL = this.constantService.PRODUCTION_SNAPSHOT_URL;
    this.USERS_URL = this.constantService.USERS_URL;
    this.FUTURE_PROJECTS_URL = this.constantService.FUTURE_PROJECTS_URL;

    this.EXCEPTION_MANAGEMENT_URL = this.constantService.EXCEPTION_MANAGEMENT_URL;
    this.BILLING_PROCESS_HISTORY_URL = this.constantService.MANAGE_BILLING_ACTIVITY_URL;
    this.SUBMISSION_SUMMARY_URL = environment.SUBMISSION_SUMMARY_URL;
    this.SOLVE_SYNC_URL = this.constantService.SOLVE_SYNC_URL;
    this.changeDetectorRefCheck();

    this.ngZone.run(() => {
      setTimeout(async () => {
         this.commonService.getJwtToken((status: any) => {
             console.log('getJwtToken Response status==>>', status);
             this.commonService.getGroups((azureGroup: any) => {
                 /**
                  *  set cookie and expiry-time
                  */
                 setInterval(setExpiry, 60 * 1000);
                 function setExpiry() {
                     const expirydate = new Date();
                     expirydate.setMinutes(expirydate.getMinutes() + 2);
                     document.cookie = "username=login; expires=" + expirydate.toUTCString();
                 }
                 this.azureGroup = azureGroup;
                 this.getMenuPermission();

                 setTimeout(() => {
                   const currentUser = localStorage.getItem("currentUser") ? JSON.parse(localStorage.getItem("currentUser")!) : "";
                   let userName = "";
                   if (currentUser) {
                       if (currentUser && currentUser.displayName) {
                           userName = currentUser.displayName;
                       }
                       analytics.identify(userName, {
                           name: userName,
                           email: currentUser.userPrincipalName,
                       });
                   }
                   this.loadMenulistFlag = true;
                   this.changeDetectorRefCheck();
                   this.init();
                   console.log('Leftmenu list azureGroup =====>>>', azureGroup, this.loadMenulistFlag, this.showDataMasterGroup);
                 }, 500);
             }); 
         });  
      }, 1000);
     });

  }

  init(){
    $(function (this: any) {
      // scripts for "menu-left" module
      /////////////////////////////////////////////////////////////////////////////////////////
      // add backdrop

      $(".cat__menu-left").after(
        '<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>'
      );
      /////////////////////////////////////////////////////////////////////////////////////////
      // submenu
      $(".cat__menu-left__submenu").on("click", function () {
        const active = $(".cat__menu-left__item");
        if (active.hasClass("cat__menu-left__item--active")) {
          active.removeClass("cat__menu-left__item--active");
        }
      });

      $(".dashboard").on("click", () => {
        const dashboard = $(".dashboard");
        if (!dashboard.hasClass("cat__menu-left__item--active")) {
          dashboard.addClass("cat__menu-left__item--active");
        }
        if ($("body").hasClass("cat__theme--light") || $("body").width() < 768) {
          const parent = $(this).parent(),
            opened = $(".cat__menu-left__submenu--toggled");

          if (
            !parent.hasClass("cat__menu-left__submenu--toggled") &&
            !parent.parent().closest(".cat__menu-left__submenu").length
          ) {
            opened
              .removeClass("cat__menu-left__submenu--toggled")
              .find("> .cat__menu-left__list")
              .slideUp(200);
          }
          parent.toggleClass("cat__menu-left__submenu--toggled");
          parent.find("> .cat__menu-left__list").slideToggle(200);
        }
      });

      $(".cat__menu-left__submenu > a").on("click", function (this: any) {
        if ($("body").hasClass("cat__theme--light") || $("body").width() < 768) {
          const parent = $(this).parent(),
            opened = $(".cat__menu-left__submenu--toggled");
          if (
            !parent.hasClass("cat__menu-left__submenu--toggled") &&
            !parent.parent().closest(".cat__menu-left__submenu").length
          ) {
            opened
              .removeClass("cat__menu-left__submenu--toggled")
              .find("> .cat__menu-left__list")
              .slideUp(200);
          }
          parent.toggleClass("cat__menu-left__submenu--toggled");
          parent.find("> .cat__menu-left__list").slideToggle(200);
        }
      });

      /////////////////////////////////////////////////////////////////////////////////////////
      // custom scroll init

      if ($("body").hasClass("cat__theme--light")) {
        if (!/Mobi/.test(navigator.userAgent) && jQuery().jScrollPane) {
          $(".cat__menu-left__inner").each(function (this: any) {
            $(this).jScrollPane({
              contentWidth: "0px",
              autoReinitialise: false,
              autoReinitialiseDelay: 100,
            });
            const api = $(this).data("jsp");
            let throttleTimeout: any;
            $(window).bind("resize", function () {
              if (!throttleTimeout) {
                throttleTimeout = setTimeout(function () {
                  api.reinitialise();
                  throttleTimeout = null;
                }, 50);
              }
            });
          });
        }
      }

      /////////////////////////////////////////////////////////////////////////////////////////
      // toggle menu

      $(".cat__menu-left__action--menu-toggle").on("click", function (this: any) {
        if ($("body").width() < 768) {
          $("body").toggleClass("cat__menu-left--visible--mobile");
        } else {
          $("body").toggleClass("cat__menu-left--visible");
        }
      });

      $(".cat__menu-left__action--backdrop-toggle").on("click", function () {
        $("body").removeClass("cat__menu-left--visible--mobile");
      });

      /////////////////////////////////////////////////////////////////////////////////////////
      // colorful menu

      const colorfulClasses =
          "cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow",
        colorfulClassesArray = colorfulClasses.split(" ");

      function setColorfulClasses() {
        $(".cat__menu-left__list--root > .cat__menu-left__item").each(function (this: any) {
          const randomClass =
            colorfulClassesArray[Math.floor(Math.random() * colorfulClassesArray.length)];
          $(this).addClass(randomClass);
        });
      }

      function removeColorfulClasses() {
        $(".cat__menu-left__list--root > .cat__menu-left__item").removeClass(colorfulClasses);
      }

      if ($("body").hasClass("cat__menu-left--colorful")) {
        setColorfulClasses();
      }

      $("body").on("setColorfulClasses", function () {
        setColorfulClasses();
      });

      $("body").on("removeColorfulClasses", function () {
        removeColorfulClasses();
      });
    });

        //   // scripts for "menu-left" module
    //   /////////////////////////////////////////////////////////////////////////////////////////
    //   // add backdrop

    //   $(".cat__menu-left").after(
    //     '<div class="cat__menu-left__backdrop cat__menu-left__action--backdrop-toggle"><!-- --></div>'
    //   );
    //   /////////////////////////////////////////////////////////////////////////////////////////
    //   // submenu
    //   $(".cat__menu-left__submenu").on("click", function () {
    //     const active = $(".cat__menu-left__item");
    //     if (active.hasClass("cat__menu-left__item--active")) {
    //       active.removeClass("cat__menu-left__item--active");
    //     }
    //   });

    //   $(".dashboard").on("click", function () {
    //     const dashboard = $(".dashboard");
    //     if (!dashboard.hasClass("cat__menu-left__item--active")) {
    //       dashboard.addClass("cat__menu-left__item--active");
    //     }
    //     if (
    //       $("body").hasClass("cat__theme--light") ||
    //       $("body").width() < 768
    //     ) {
    //       const parent = $(this).parent(),
    //         opened = $(".cat__menu-left__submenu--toggled");

    //       if (
    //         !parent.hasClass("cat__menu-left__submenu--toggled") &&
    //         !parent.parent().closest(".cat__menu-left__submenu").length
    //       ) {
    //         opened
    //           .removeClass("cat__menu-left__submenu--toggled")
    //           .find("> .cat__menu-left__list")
    //           .slideUp(200);
    //       }
    //       parent.toggleClass("cat__menu-left__submenu--toggled");
    //       parent.find("> .cat__menu-left__list").slideToggle(200);
    //     }
    //   });

    //   $(".cat__menu-left__submenu > a").on("click", function () {
    //     if (
    //       $("body").hasClass("cat__theme--light") ||
    //       $("body").width() < 768
    //     ) {
    //       const parent = $(this).parent(),
    //         opened = $(".cat__menu-left__submenu--toggled");
    //       if (
    //         !parent.hasClass("cat__menu-left__submenu--toggled") &&
    //         !parent.parent().closest(".cat__menu-left__submenu").length
    //       ) {
    //         opened
    //           .removeClass("cat__menu-left__submenu--toggled")
    //           .find("> .cat__menu-left__list")
    //           .slideUp(200);
    //       }
    //       parent.toggleClass("cat__menu-left__submenu--toggled");
    //       parent.find("> .cat__menu-left__list").slideToggle(200);
    //     }
    //   });

    //   /////////////////////////////////////////////////////////////////////////////////////////
    //   // custom scroll init

    //   if ($("body").hasClass("cat__theme--light")) {
    //     if (!/Mobi/.test(navigator.userAgent) && jQuery().jScrollPane) {
    //       $(".cat__menu-left__inner").each(function () {
    //         $(this).jScrollPane({
    //           contentWidth: "0px",
    //           autoReinitialise: false,
    //           autoReinitialiseDelay: 100,
    //         });
    //         const api = $(this).data("jsp");
    //         let throttleTimeout: any;
    //         $(window).bind("resize", function () {
    //           if (!throttleTimeout) {
    //             throttleTimeout = setTimeout(function () {
    //               api.reinitialise();
    //               throttleTimeout = null;
    //             }, 50);
    //           }
    //         });
    //       });
    //     }
    //   }

    //   /////////////////////////////////////////////////////////////////////////////////////////
    //   // toggle menu

    //   $(".cat__menu-left__action--menu-toggle").on("click", function () {
    //     if ($("body").width() < 768) {
    //       $("body").toggleClass("cat__menu-left--visible--mobile");
    //     } else {
    //       $("body").toggleClass("cat__menu-left--visible");
    //     }
    //   });

    //   $(".cat__menu-left__action--backdrop-toggle").on("click", function () {
    //     $("body").removeClass("cat__menu-left--visible--mobile");
    //   });

    //   /////////////////////////////////////////////////////////////////////////////////////////
    //   // colorful menu

    //   var colorfulClasses =
    //       "cat__menu-left--colorful--primary cat__menu-left--colorful--secondary cat__menu-left--colorful--primary cat__menu-left--colorful--default cat__menu-left--colorful--info cat__menu-left--colorful--success cat__menu-left--colorful--warning cat__menu-left--colorful--danger cat__menu-left--colorful--yellow",
    //     colorfulClassesArray = colorfulClasses.split(" ");

    //   function setColorfulClasses() {
    //     $(".cat__menu-left__list--root > .cat__menu-left__item").each(
    //       function () {
    //         var randomClass =
    //           colorfulClassesArray[
    //             Math.floor(Math.random() * colorfulClassesArray.length)
    //           ];
    //         $(this).addClass(randomClass);
    //       }
    //     );
    //   }

    //   function removeColorfulClasses() {
    //     $(".cat__menu-left__list--root > .cat__menu-left__item").removeClass(
    //       colorfulClasses
    //     );
    //   }

    //   if ($("body").hasClass("cat__menu-left--colorful")) {
    //     setColorfulClasses();
    //   }

    //   $("body").on("setColorfulClasses", function () {
    //     setColorfulClasses();
    //   });

    //   $("body").on("removeColorfulClasses", function () {
    //     removeColorfulClasses();
    //   });
    // });
  }
  ngOnDestroy() {
    console.log("Destroy............");
  }

  get menuList(){
    return this.azureGroup;
  }

  openReleaseNoteModal() {
    console.log("releaseNotesreleaseNotes............");
    //$("#releaseNoteDisplay").modal({ show: true, backdrop: "static" });
  }

  closeRNModal() {
    console.log("releaseNotesreleaseNotes............");
    // $("#releaseNoteDisplay").modal("hide");
  }
  getMenuPermission() {
    const currentMenuItems = localStorage.getItem("menuItems")
      ? JSON.parse(localStorage.getItem("menuItems")!)
      : null;
    let weightagess = 0;
    let weightagePD = 0;
    if (currentMenuItems) {
      for (let i = 0; i < currentMenuItems.length; i++) {
        if (currentMenuItems[i].menu && currentMenuItems[i].menu === "SO") {
          weightagess = currentMenuItems[i].weightage;
        }

        if (currentMenuItems[i].menu && currentMenuItems[i].menu === "PD") {
          weightagePD = currentMenuItems[i].weightage;
        }

        if (
          currentMenuItems[i].menu &&
          (currentMenuItems[i].menu === "SI" ||
            currentMenuItems[i].menu === "JO" ||
            currentMenuItems[i].menu === "RO" ||
            currentMenuItems[i].menu === "MG" ||
            currentMenuItems[i].menu === "SC" ||
            currentMenuItems[i].menu === "FL" ||
            currentMenuItems[i].menu === "RS" ||
            currentMenuItems[i].menu === "GI" ||
            currentMenuItems[i].menu === "MP" ||
            currentMenuItems[i].menu === "CS" ||
            currentMenuItems[i].menu === "MD" ||
            currentMenuItems[i].menu === "IR" ||
            currentMenuItems[i].menu === "IS")
        ) {
          this.showManageGroup = true;
        }
        if (
          currentMenuItems[i].menu &&
          (currentMenuItems[i].menu === "GO" ||
            currentMenuItems[i].menu === "SO" ||
            currentMenuItems[i].menu === "GS")
        ) {
          this.showDataMasterGroup = true;
        }
      }
      if (weightagess > this.constantService.ADMIN_WEIGTAGE) {
        this.displayAdminActionEnable = true;
      } else {
        this.displayAdminActionEnable = false;
      }

      if (weightagePD > this.constantService.ADMIN_WEIGTAGE) {
        this.displayAdminActionEnablePD = true;
      } else {
        this.displayAdminActionEnablePD = false;
      }
    }
    this.changeDetectorRefCheck();
  }

  changeDetectorRefCheck() {
    this.changeDetectorRef.markForCheck();
  }

}
